import React, { useState } from "react"
import PageWrapper from "../components/PageWrapper";
import AcneContent from '../sections/aapeel/peelcontent.js';
import Faq from '../sections/aapeel/peelfaq.js';
import Head from "../components/head";
import { JsonLd } from '../components/JsonLd';
import Trend from '../components/trends.js';
import Clinic from '../components/NewFind.js'; 
import Saveface from '../components/save.js';
import HeroComponent from "../components/ImageHero.js";
import Testimonial from "../sections/index/indexreviews.js";
import imgVideo from "../assets/image/jpeg/acne1.jpg";
import Glance from "../components/glance.js";
import { Container, Row, Col } from "react-bootstrap";
import { Box, Section } from "../components/Core";
import PopupForm from "../components/PopupForm/PopupForm"

const AcnePeelPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
  <>
 <Head title="Acne Peel in London" image="/images/dermamina-home.jpg"  description="Acne peel treatments use chemical solutions like glycolic or salicylic acid to exfoliate skin, reduce acne scars, and improve skin texture for a clearer complexion" keywords="acne peel treatment, chemical peel acne, acne scar peel, glycolic acid peel, salicylic acid peel, TCA peel acne, peel for acne scars, acne peel benefits, acne peel results, professional acne peel"/>

<JsonLd>
{{
'@context': 'https://schema.org',
'@type': 'Organization',
'@id': 'https://www.dermamina.com/#organization',
url: 'https://www.dermamina.com',
name: 'Dermamina',
contactPoint: {
  '@type': 'ContactPoint',
  telephone: '020 7099 6650',
  contactType: 'Customer service',
},
address:{
  '@type': 'PostalAddress',
streetAddress: '114 New Cavendish Street',
addressRegion:'London',
postalCode:'W1W 6XT',
addressLocality:'Westminster',
},
image: {
  '@type': 'ImageObject',
  url: 'https://dermamina.com/images/dermamina-home.jpg',   
},
logo: {
'@type': 'ImageObject',
url: 'https://dermamina.com/images/dermamina-logo.svg',   
width: '200',
},
'name': 'dermamina',
'sameAs': [

'https://www.facebook.com/DermaMina-1985252831708400/',
'https://www.instagram.com/dermamina',
],
}}
</JsonLd>

<JsonLd>
{{
'@context': 'https://schema.org',
'@type': 'BreadcrumbList',
'itemListElement': [
  {'@type': 'ListItem', 
position:1,
name:'Dermamina Clinic',
item:'https://www.dermamina.com',
},

{'@type': 'ListItem', 
position:2,
name:'Non-Surgical Rhinoplasty',
item:'https://www.dermamina.com/non-surgical-nose-job',
},

{'@type': 'ListItem', 
position:3,
name:'Tear Trough Fillers Under Eye',
item:'https://www.dermamina.com/tear-trough-under-eye',
},

]
}}
</JsonLd>
      <PageWrapper headerDark footerDark>
        <PopupForm
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      <HeroComponent
    itext='<span style="color: #1a1a1a;">Acne Salicylic </span> <span style="color: #00aec7;">Peel</span>'
    secondText="Reveal Radiant, Clear Skin"
    videoAlt="Acne Peel"
    imgVideo={imgVideo} // Specify your image URL here
    setIsModalOpen={setIsModalOpen}
  />

<Section py={0} className="pt-lg-5">
    <Container >
      <Row>
        <Col lg="12" xs="12" data-aos="fade-up"
              data-aos-duration="500"
              data-aos-once="false"
              data-aos-delay="100">
  <Glance  best="4+ treatments"
  bestalt="best Acne Peel"
  resultstext="Long Lasting"
  resultsalt="Acne Peel results"
  costdata="£150"
  costalt="Acne Peel Cost"
  timedata="10 Minutes"
  timealt="Acne Peel Duration Time"
  workdata="Immediately"
  workalt="Acne Peel downtime work"
  paindata="None / Mild"
  painalt="Acne Peel Pain"/>
 </Col>
  </Row>
  </Container>
  </Section>  
      <AcneContent
        setIsModalOpen={setIsModalOpen}
      />
      <Faq
        setIsModalOpen={setIsModalOpen}
      />
      <Testimonial />
    
      <Clinic />
      <Saveface />
      <Trend />
     </PageWrapper>
 
  </>
)}
export default AcnePeelPage
