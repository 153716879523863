import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { Title, Section, Box, Text, Span } from "../../components/Core";
import { device } from "../../utils";
import imgMobile from "../../assets/image/jpeg/acne4.jpg";
import Newarrow from '../../assets/image/jpeg/enter.svg'


const Bookbutton = styled.button`
font-size: 16px;
padding: 12px 34px;
    background-color: #006b7b;
    display: block;
    color: #fff;
    border-radius: 32px;
    transition: .5s;
    border: none;
   

  @media screen and (max-width: 767px) {
    background-color: #006b7b;
    display: inline-block;
    padding: 12px 34px;
    font-size: 14px;
  }
`;

const Separator = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #ffffff;

  ::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: #f7f7fb;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }
`;

const SeparatorEnd = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #f7f7fb;

  ::before, ::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
  }

  ::before {
    top: 0;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }

  ::after {
    bottom: 0;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }
`;

const Iwrap = styled.div`
  display: flex;
  margin-bottom: 1em;
  align-items: center;

  @media screen and (max-width: 767px) {
    margin-bottom: 8px;
    justify-content: flex-start;
  }

  @media screen and (max-width: 991px) {
    margin-bottom: 8px;
    justify-content: center;
    text-align: center;
  }
`;

const Itext = styled.div`
  margin-top: 0px;
  margin-bottom: 5px;
  color: #1a1a1a;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;

  span{
    color:#00aec7;
  }

  @media screen and (max-width: 991px) {
    font-size: 12px;
  }
`;



const SecondText = styled.h3`
  font-size: 2.2em;
  margin-bottom: 20px;
  color: #1a1a1a;
  font-weight: 200;
  letter-spacing: 2px;

  @media screen and (max-width: 767px) {
    margin-bottom: 24px;
    font-size: 16px;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
    text-align: center;
  }
`;


const ContentCard = ({ iconImage, title, className, children, ...rest }) => (
  <Box
    {...rest}
    className={`d-flex align-items-start justify-content-start ${className}`}
  >
    <Box mr={4}>
      <img src={iconImage} alt="" />
    </Box>
    <Box>
      <Title variant="card" fontSize="24px" mb={3}>
        {title}
      </Title>
      <Text variant="small">{children}</Text>
    </Box>
  </Box>
);

const ImgStyled = styled.img`

  box-shadow: ${({ theme }) => `0 52px 54px ${theme.colors.shadow}`};
  border-radius: 0px;
  max-width: 100%;
  @media ${device.sm} {
    max-width: 50%;
  }
  @media ${device.md} {
    max-width: 33%;
  }
  @media ${device.lg} {
    max-width: 100%;
  }
  @media ${device.xl} {
    
  }
`;

const PeelContentPage = ({setIsModalOpen}) => (
  <>
  <Separator />
    {/* <!-- Content section 2 --> */}
    <Section py={4} bg="#f7f7fb">
      <Container className="pt-lg-5 pb-lg-5 pt-3 pb-3">

          <Row className="pb-5"  data-aos="fade-up"
            data-aos-duration="500"
            data-aos-once="false"
            data-aos-delay="300">
              <Col  >
                <Iwrap>

<Itext as="h2">Acne <span>Peel</span></Itext>
</Iwrap>
      <SecondText>
      What is Acne Peel?

      </SecondText>
              </Col>
          </Row>
        <Row className="align-items-center"  data-aos="fade-up"
            data-aos-duration="500"
            data-aos-once="false"
            data-aos-delay="300">
          <Col lg="6" className="mb-5 mb-lg-0">
            <div
              className=""
              data-aos="fade-up"
              data-aos-duration="750"
              data-aos-delay="300"
              data-aos-once="false"
            >
              <img src={imgMobile} alt="What is Acne Peel" className="rounded shadow" />
            </div>
          </Col>
          <Col data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-once="false"
            data-aos-delay="300" lg="6" md="9">
            <Box className=" pt-lg-0">

              <Text>

                Not only are these peels Medical Grade skin care but a Medically Licensed skin solution
                too. <br /><br />


                Acne affects millions around the world and we understand how upsetting it can be for many to go through
                acne symptoms. Our mission at Dermamina London is to help you feel confident in the way your skin looks
                and feel.<br /><br />


                At Dermamina we use EnerPeel® Salicylic Acid Skinpeels for our acne treatment London, which targets all
                visible symptoms and the unseen primary causes of acne, by diminishing oil production, destroying acne
                causing bacteria, and reducing inflammation.<br /><br />


              </Text>
              <div onClick={() => setIsModalOpen(true)}
                   activeClassName="active">
                <Bookbutton>Book appointment</Bookbutton>
              </div>
            </Box>
          </Col>
        </Row>


      </Container>


    </Section>
    <SeparatorEnd />
  </>
);

export default PeelContentPage;
